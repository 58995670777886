import styled from 'styled-components';

import Img from 'gatsby-image';
import { animated } from 'react-spring/renderprops';

export const Thumbnails = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`;

export const Image = styled(Img)`
  height: 350px;
  overflow: hidden;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

export const ThumbnailItem = styled(animated.a)`
  overflow: hidden;
`;
