import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { FluidImagePropTypes } from 'types';

import {
  ImageBoxes,
  ImageBoxesPropTypes,
} from 'components/common/ImageBoxes/ImageBoxes';
import { HTMLContent } from 'components/common/HTMLContent/HTMLContent';
import { Fade } from 'components/common/Animations';
import { PageSection } from 'components/common/PageSection/PageSection';
import { PageContent } from 'components/common/PageContent/PageContent';
import { InfiniteGallery } from 'components/common/InfiniteGallery/InfiniteGallery';
import { SecondaryHeader } from 'components/common/Typography';
import { Image } from 'components/common/Image/Image';

import { Wrapper } from './AboutStyles';

export const About = ({
  content,
  employees,
  gallery,
  image,
  isHTML,
  isMultiRow,
  title,
}) => (
  <>
    <Image
      alt="About hero"
      as={image?.childImageSharp?.fluid ? Image : 'img'}
      fluid={image?.childImageSharp?.fluid}
      src={image}
    />
    <PageSection backgroundColor="light">
      <Wrapper>
        <Fade>
          <SecondaryHeader isBolded isUnderlined>
            {title}
          </SecondaryHeader>
        </Fade>
        <Fade>
          <HTMLContent content={content} isHTML={isHTML} />
        </Fade>
      </Wrapper>
    </PageSection>
    <PageSection>
      <PageContent>
        <Fade>
          <ImageBoxes isMultiRow={isMultiRow} items={employees} />
        </Fade>
      </PageContent>
    </PageSection>
    {!!gallery.length && <InfiniteGallery images={gallery} />}
  </>
);

export const AboutPropTypes = {
  employees: ImageBoxesPropTypes.isRequired,
  gallery: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string]),
    })
  ),
  image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string])
    .isRequired,
  isMultiRow: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

About.propTypes = {
  content: PropTypes.node.isRequired,
  isHTML: PropTypes.bool,
  ...AboutPropTypes,
};

About.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  gallery: [],
  isHTML: false,
};

export const aboutPageFragment = graphql`
  fragment AboutPageFragment on MarkdownRemark {
    frontmatter {
      title
      isMultiRow
      employees {
        description
        image {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
        name
      }
      gallery {
        image {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      image {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  }
`;
