import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { PageMetaDataPropTypes } from 'types';

import { Layout } from 'components/Layout/Layout';
import { About, AboutPropTypes } from 'components/About/About';

const AboutPage = ({
  data: {
    markdownRemark: {
      frontmatter: { pageDescription, pageKeywords, pageTitle, ...rest },
      // eslint-disable-next-line react/prop-types
      html,
    },
  },
}) => (
  <Layout
    description={pageDescription}
    keywords={pageKeywords}
    title={pageTitle}
  >
    <About content={html} isHTML {...rest} />
  </Layout>
);

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        ...PageMetaDataPropTypes,
        ...AboutPropTypes,
      }),
      html: PropTypes.node.isRequired,
    }),
  }).isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      ...PageMetaDataFragment
      ...AboutPageFragment
    }
  }
`;
