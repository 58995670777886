import React from 'react';
import PropTypes from 'prop-types';

import { FluidImagePropTypes } from 'types';
import { withMedia } from 'utils';

import { InfiniteScroll } from 'components/common/InfiniteScroll/InfiniteScroll';
import { Slider } from 'components/common/Slider/Slider';
import { Gallery } from 'components/common/Gallery/Gallery';

const mapImages = (images) =>
  images.map(({ image }) =>
    image?.childImageSharp?.fluid
      ? {
          src: image.childImageSharp.fluid.src,
          srcSet: image.childImageSharp.fluid.srcSet,
        }
      : { src: image }
  );

const renderGallery = (images) => (itemsToDisplay) => {
  const reducedGallery = images.reduce((total, image, index) => {
    if (itemsToDisplay >= index + 1) {
      total.push(image);
    }

    return total;
  }, []);

  return (
    <Gallery images={mapImages(reducedGallery)} thumbnails={reducedGallery} />
  );
};

export const InfiniteGallery = withMedia(({ images, isMatched }) =>
  isMatched ? (
    <InfiniteScroll
      initialItemsCount={3}
      itemsCount={images.length}
      renderItems={renderGallery(images)}
    />
  ) : (
    <Slider images={images} />
  )
);

InfiniteGallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string]),
    })
  ).isRequired,
  isMatched: PropTypes.string,
};
