import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FluidImagePropTypes } from 'types';
import { Transition } from 'react-spring/renderprops';

import Lightbox from 'react-images';

import { Thumbnails, ThumbnailItem, Image } from './GalleryStyles';

export class Gallery extends Component {
  state = {
    currentIndex: 0,
    isOpen: false,
  };

  handleImageChange = (direction) =>
    this.setState(({ currentIndex }) => ({
      currentIndex: currentIndex + direction,
    }));

  handleClose = () => this.setState({ isOpen: false });

  handleOpen = (e, currentIndex) => {
    e.preventDefault();
    this.setState({ currentIndex, isOpen: true });
  };

  render() {
    const { currentIndex, isOpen } = this.state;
    const { images, thumbnails } = this.props;

    return (
      <>
        <Thumbnails>
          <Transition
            enter={{ opacity: 1, transform: 1 }}
            from={{ opacity: 0, transform: 0.6 }}
            initial={null}
            items={thumbnails}
            keys={({ image }) => image.id || image}
            leave={{ opacity: 0, transform: 0.6 }}
            native
          >
            {({ image }, state, index) => ({ opacity, transform }) => (
              <ThumbnailItem
                key={image.id || image}
                href="/"
                onClick={(e) => this.handleOpen(e, index)}
                style={{
                  opacity,
                  transform: transform.interpolate((x) => `scale(${x})`),
                }}
              >
                <Image
                  alt={`Thumbnail gallery ${index + 1}`}
                  as={image?.childImageSharp?.fluid ? Image : 'img'}
                  fluid={image?.childImageSharp?.fluid}
                  src={image}
                />
              </ThumbnailItem>
            )}
          </Transition>
        </Thumbnails>
        <Lightbox
          backdropClosesModal
          currentImage={currentIndex}
          images={images}
          isOpen={isOpen}
          onClickNext={() => this.handleImageChange(1)}
          onClickPrev={() => this.handleImageChange(-1)}
          onClose={this.handleClose}
          width={1366}
        />
      </>
    );
  }
}

Gallery.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      srcSet: PropTypes.string,
    })
  ).isRequired,
  thumbnails: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([FluidImagePropTypes, PropTypes.string]),
    })
  ).isRequired,
};
